import React from 'react';
import {
  Datagrid,
  TextField,
  NumberInput,
  SelectInput,
  BooleanInput,
  FunctionField,
} from 'react-admin';

import DateTimeField from 'components/DateTimeField';
import IdInput from 'components/Filter/IdInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import { MarketFilter, SalesAssociateFilter, AccountManagerFilter } from 'components/Filter';
import LinkField from 'components/LinkField';
import { DisplayHelpers } from 'utils/helpers';
import List from 'components/List';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import ReferenceInput from 'components/ReferenceInput';
import MoneyField from 'components/MoneyField';
import IdField from 'components/IdField';
import DateFilter from 'components/Filter/DateFilter';
import CompanyInput from 'components/CompanyInput';
import { differenceInHours } from 'date-fns';
import { gql } from '@apollo/client';

const JobsFilters = [
  <IdInput />,
  <NumberInput
    label="Professional ID"
    source="professional_id"
    alwaysOn
    data-testid="jobs-professional_id_input"
  />,
  <NumberInput
    label="Location ID"
    source="location_id"
    alwaysOn
    data-testid="jobs-location_id_input"
  />,
  <CompanyInput alwaysOn source="search.company_id" />,
  <EnumSelectInput emptyText="All" source="status" alwaysOn />,
  <MarketFilter label="Market" alwaysOn />,
  <AccountManagerFilter alwaysOn label="Account Manager" source="search.account_manager_id" />,
  <SalesAssociateFilter label="Sales Associate" alwaysOn source="search.sales_associate_id" />,
  <PositionInput alwaysOn multiple source="search.position_ids" />,
  <SpecialtyInput
    alwaysOn
    hideIfEmpty
    multiple
    source="search.specialty_ids"
    positionSource="search.position_ids"
  />,
  <DateFilter label="Starts Date" source="starts_date" alwaysOn />,
  <DateFilter label="Ends Date" source="ends_date" alwaysOn />,
  <ReferenceInput alwaysOn reference="LocationType" source="search.location_type_id">
    <SelectInput
      resettable
      optionText="label"
      label="Location Type"
      data-testid="jobs-location_type_filter"
    />
  </ReferenceInput>,
  <BooleanInput
    label="First Job Posted"
    source="search.first_job_posted"
    alwaysOn
    data-testid="jobs-first_job_posted_filter"
  />,
  <BooleanInput
    label="Used Favorites"
    source="search.used_favorites"
    alwaysOn
    data-testid="jobs-used_favorites_filter"
  />,
  <BooleanInput
    label="First Job Booked"
    source="search.first_job_booked"
    alwaysOn
    data-testid="jobs-first_job_booked_filter"
  />,
  <BooleanInput
    label="Has Pay Boost"
    source="search.has_pay_boost"
    alwaysOn
    data-testid="jobs-has_pay_boost_filter"
  />,
  <SelectInput
    label="Job Type"
    source="search.job_type"
    alwaysOn
    emptyText="All"
    choices={[
      { id: 'per_diem', name: 'Per Diem' },
      { id: 'assignment', name: 'Assignment' },
    ]}
    data-testid="jobs-job_type_filter"
  />,
  <EnumSelectInput alwaysOn source="search.billing_group_plan" label="Plan" />,
  <SelectInput
    choices={[
      { id: 'marketplace', name: 'Marketplace' },
      { id: 'saas', name: 'BYOP' },
      { id: 'both', name: 'Both' },
    ]}
    source="release_audience"
    label="BYOP Release Audience"
  />,
  <SelectInput
    alwaysOn
    choices={[
      { id: 0, name: '0-4 hours' },
      { id: 1, name: '4-6 hours' },
      { id: 2, name: '6-8.5 hours' },
      { id: 3, name: '8.5-10 hours' },
      { id: 4, name: '10-12 hours' },
      { id: 5, name: '12+ hours' },
    ]}
    source="search.shift_hours"
    label="# Shift Hours"
    data-testid="jobs-shift_hours_filter"
  />,
  <SelectInput
    alwaysOn
    choices={[
      { id: true, name: 'W-2' },
      { id: false, name: '1099' },
    ]}
    source="is_w2"
    label="Tax Type"
    data-testid="jobs-is_w2_filter"
  />,
  <BooleanInput
    label="Booked by Agency"
    source="search.booked_by_agency"
    alwaysOn
    data-testid="jobs-booked_by_agency_filter"
  />,
  <NumberInput
    label="Agency ID"
    source="search.agency_id"
    alwaysOn
    data-testid="jobs-agency_id_input"
  />,
];

const JobsList = () => (
  <List exporter={false} filters={JobsFilters} data-testid="jobs-list">
    <Datagrid bulkActionButtons={false} data-testid="jobs-list-table">
      <IdField />
      <LinkField label="Professional" source="professional_id" />
      <LinkField label="Location" source="location_id" />
      <FunctionField
        label="Shift Type"
        render={() => <TextField source="shift_type" data-testid="jobs-shift_type_field" />}
      />
      <DateTimeField label="Created At" source="created_at" tzSource="location.timezone_lookup" />
      <DateTimeField
        format="MM/dd/yy"
        label="Date"
        source="starts_time"
        tzSource="location.timezone_lookup"
      />
      <DateTimeField
        format="HH:mm zzz"
        label="Start Time"
        source="starts_time"
        tzSource="location.timezone_lookup"
      />
      <FunctionField
        label="End Time"
        render={(record) => {
          if (record.shift_type === 'call_back') {
            return (
              <DateTimeField
                format="HH:mm zzz"
                source="callback_ends_time"
                tzSource="location.timezone_lookup"
              />
            );
          } else if (record.shift_type === 'on_call') {
            return (
              <DateTimeField
                format="HH:mm zzz"
                source="on_call_ends_time"
                tzSource="location.timezone_lookup"
              />
            );
          } else {
            return (
              <DateTimeField
                format="HH:mm zzz"
                source="ends_time"
                tzSource="location.timezone_lookup"
              />
            );
          }
        }}
      />
      <FunctionField
        label="# of Shift Hours"
        data-testid="jobs-shift_hours_field"
        render={(record) => {
          let endsTime;
          if (record.shift_type === 'call_back') {
            endsTime = record.callback_ends_time;
          } else if (record.shift_type === 'on_call') {
            endsTime = record.on_call_ends_time;
          } else {
            endsTime = record.ends_time;
          }
          return differenceInHours(new Date(endsTime), new Date(record.starts_time));
        }}
      />
      <TextField label="Status" source="status" data-testid="jobs-status_field" />
      <FunctionField
        label="Position"
        render={DisplayHelpers.professional.positionNames}
        data-testid="jobs-position_field"
      />
      <TextField
        label="Market"
        source="location.market.display_name"
        data-testid="jobs-market_field"
      />
      <MoneyField label="Pay Boost" source="payout_bonus_cents" />
      <FunctionField
        label="Tax Type"
        data-testid="jobs-is_w2_field"
        render={(record) => {
          return record.is_w2 ? 'W-2' : '1099';
        }}
      />
      <LinkField label="Agency" source="professional.account.agency_id" typename="agency" />
    </Datagrid>
  </List>
);

JobsList.query = gql`
  query GET_LIST($input: JobQueryInput) {
    _jobsMeta(input: $input) {
      count
    }

    jobs(input: $input) {
      id
      professional_id
      professional {
        id
        account {
          id
          agency_id
        }
      }
      location_id
      shift_type
      created_at
      location {
        id
        timezone_lookup
        market {
          id
          display_name
          state {
            id
            name
            covid_requirement
          }
        }
        state {
          id
          name
          covid_requirement
        }
        covid_requirement
        name
        company {
          id
        }
      }
      starts_time
      callback_starts_time
      callback_ends_time
      on_call_starts_time
      on_call_ends_time
      ends_time
      job_billing_sums {
        total_hours
        total_breaks_minutes
      }
      status
      positions {
        id
        display_name
      }
      payout_bonus_cents
      starts_date
      favorite_ids
      is_w2
    }
  }
`;
export default JobsList;
